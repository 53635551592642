import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2f434432 = () => interopDefault(import('../pages/aboutUs/index.vue' /* webpackChunkName: "pages/aboutUs/index" */))
const _6200313f = () => interopDefault(import('../pages/cloudGuard/index.vue' /* webpackChunkName: "pages/cloudGuard/index" */))
const _0cb53d45 = () => interopDefault(import('../pages/contactUs/index.vue' /* webpackChunkName: "pages/contactUs/index" */))
const _7023f870 = () => interopDefault(import('../pages/dedicated-server/index.vue' /* webpackChunkName: "pages/dedicated-server/index" */))
const _4b2e73fd = () => interopDefault(import('../pages/enterprise-solutions/index.vue' /* webpackChunkName: "pages/enterprise-solutions/index" */))
const _2cd55c11 = () => interopDefault(import('../pages/iran-vps/index.vue' /* webpackChunkName: "pages/iran-vps/index" */))
const _20d67d72 = () => interopDefault(import('../pages/iran-windows-vps/index.vue' /* webpackChunkName: "pages/iran-windows-vps/index" */))
const _ad9f2bea = () => interopDefault(import('../pages/linux-server/index.vue' /* webpackChunkName: "pages/linux-server/index" */))
const _7ec336f2 = () => interopDefault(import('../pages/manageServices/index.vue' /* webpackChunkName: "pages/manageServices/index" */))
const _77ec5a5c = () => interopDefault(import('../pages/migrate/index.vue' /* webpackChunkName: "pages/migrate/index" */))
const _9cfae61c = () => interopDefault(import('../pages/paas/index.vue' /* webpackChunkName: "pages/paas/index" */))
const _72dcd2c1 = () => interopDefault(import('../pages/privateCloud/index.vue' /* webpackChunkName: "pages/privateCloud/index" */))
const _9e92e058 = () => interopDefault(import('../pages/virtual-server/index.vue' /* webpackChunkName: "pages/virtual-server/index" */))
const _e7a3d2e0 = () => interopDefault(import('../pages/vpc/index.vue' /* webpackChunkName: "pages/vpc/index" */))
const _4b0d7ac0 = () => interopDefault(import('../pages/vps/index.vue' /* webpackChunkName: "pages/vps/index" */))
const _67e86b5c = () => interopDefault(import('../pages/windows-server/index.vue' /* webpackChunkName: "pages/windows-server/index" */))
const _19592146 = () => interopDefault(import('../pages/cloudGuard/cdn/index.vue' /* webpackChunkName: "pages/cloudGuard/cdn/index" */))
const _41bbe504 = () => interopDefault(import('../pages/cloudGuard/cloud-security/index.vue' /* webpackChunkName: "pages/cloudGuard/cloud-security/index" */))
const _173e6959 = () => interopDefault(import('../pages/cloudGuard/dns/index.vue' /* webpackChunkName: "pages/cloudGuard/dns/index" */))
const _dfc2c394 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3f928af3 = () => interopDefault(import('../pages/meeting/_index.vue' /* webpackChunkName: "pages/meeting/_index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutUs",
    component: _2f434432,
    name: "aboutUs"
  }, {
    path: "/cloudGuard",
    component: _6200313f,
    name: "cloudGuard"
  }, {
    path: "/contactUs",
    component: _0cb53d45,
    name: "contactUs"
  }, {
    path: "/dedicated-server",
    component: _7023f870,
    name: "dedicated-server"
  }, {
    path: "/enterprise-solutions",
    component: _4b2e73fd,
    name: "enterprise-solutions"
  }, {
    path: "/iran-vps",
    component: _2cd55c11,
    name: "iran-vps"
  }, {
    path: "/iran-windows-vps",
    component: _20d67d72,
    name: "iran-windows-vps"
  }, {
    path: "/linux-server",
    component: _ad9f2bea,
    name: "linux-server"
  }, {
    path: "/manageServices",
    component: _7ec336f2,
    name: "manageServices"
  }, {
    path: "/migrate",
    component: _77ec5a5c,
    name: "migrate"
  }, {
    path: "/paas",
    component: _9cfae61c,
    name: "paas"
  }, {
    path: "/privateCloud",
    component: _72dcd2c1,
    name: "privateCloud"
  }, {
    path: "/virtual-server",
    component: _9e92e058,
    name: "virtual-server"
  }, {
    path: "/vpc",
    component: _e7a3d2e0,
    name: "vpc"
  }, {
    path: "/vps",
    component: _4b0d7ac0,
    name: "vps"
  }, {
    path: "/windows-server",
    component: _67e86b5c,
    name: "windows-server"
  }, {
    path: "/cloudGuard/cdn",
    component: _19592146,
    name: "cloudGuard-cdn"
  }, {
    path: "/cloudGuard/cloud-security",
    component: _41bbe504,
    name: "cloudGuard-cloud-security"
  }, {
    path: "/cloudGuard/dns",
    component: _173e6959,
    name: "cloudGuard-dns"
  }, {
    path: "/",
    component: _dfc2c394,
    name: "index"
  }, {
    path: "/meeting/:index",
    component: _3f928af3,
    name: "meeting"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
