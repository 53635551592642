
import items from "~/data/menu";
import TheMenu from "../Header/TheMenu.vue";
import Modal from "../../../components/Forms/ContactForm/Modal.vue";

export default {
  props: ["isOpen", "showItems", "user", "login"],
  components: { TheMenu, Modal },

  data() {
    return {
      flag: true,
      items: items,
      drawer: false,
      currentMenu: "",
      menuObject: null,
    };
  },
  watch: {
    showItems(val) {
      console.log("Umad Inja", val);
      if (val == "") {
        this.flag = true;
        this.currentMenu = "";
        this.menuObject = null;
      }
    },
  },
  methods: {
    closeDrawer($event) {
      this.$emit("close", false);
      // this.$emit("toggle", false);
    },
    handleClick() {
      window.open("https://xaas.ir/blog/enterprise-services/", "_self");
    },
    handleClickItem(item) {
      this.flag = false;
      this.$emit("showMenu", item.title);
      this.currentMenu = item.title;
      this.menuObject = item.children;
    },
    include() {
      return [document.querySelector(".included-drawer")];
    },
  },
};
